<!--hướng dẫn
import CusttomInput from '@/views/business/pages/components/CusttomInput.vue'

 <custtom input
          :vmodel="dataUser.birthDay"    //giá trị liên kết
          :valide="false"                //có validee k
          :label="'Ngày sinh'"          //nhập tiêu đề của ô input
          :disabled="true"              // không cho nhập ô input
          @valueInput="e=>dataUser.birthDay = e"   //lấy dữ liệu từ ô input
        />
-->
<template>
  <b-form-group
    label-for="basicInput"
  >
    <label
      :for="label"
    >{{ label }}<span
      v-if="valide"
      class="required"
    >(*)</span></label>
    <validation-provider
      v-if="valide"
      #default="{ errors }"
      rules="required"
      :custom-messages="customVali"
    >
      <b-form-input
        :id="label"
        v-model=" vmodel "
        :disabled="disabled"
        :state="errors.length > 0 ? false:null"
        @input="data01(e)"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
    <b-form-input
      v-else
      id="basicInput"
      v-model="vmodel"
      :disabled="disabled"
      @input="data01(e)"
    />
  </b-form-group>
</template>

<script>
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BRow, BCol, BFormGroup, BFormTextarea, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BFormInput,
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    vmodel: {
      default: '',
    },
    // eslint-disable-next-line vue/require-prop-types
    label: {
      default: 'nhập props :label',
    },
    // eslint-disable-next-line vue/require-prop-types
    valide: {
      default: true,
    },
    // eslint-disable-next-line vue/require-prop-types
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      // valueInput: '',
      required,
      customVali: {
        required: `${this.label} là bắt buộc`,
      },
    }
  },
  methods: {
    data01() {
      this.$emit('valueInput', this.vmodel)
    },
  },

}
</script>

<style>

</style>
