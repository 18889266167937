<template>
  <div>
    <!-- ===================================== ô input nhập yêu cầu ==================================-->
    <b-row>
      <!--=============== Giá đọc tài liệu=================-->
      <b-col
        md="4"
        xl="4"
      >
        <b-form-group
          label-for="name"
        >
          <label for="name">Tên miền</label>
          <b-form-input
            v-model="dataInput.url"
          />
        </b-form-group>
      </b-col>
    </b-row></div>
</template>

<script>
import {
  BRow, BCol,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'

import CusttomInput from '@/views/management-business/business/pages/components/CusttomInput.vue'

export default {
  components: {
    CusttomInput,
    BRow,
    BFormGroup,
    BFormInput,
    BCol,
  },

  props: {
    dataInput: {
      type: Object,
      default: _ => {},
    },
  },
}
</script>

<style lang="scss">
</style>
